// https://github.com/mui-org/material-ui/tree/master/examples/create-react-app-with-jss/src
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// A theme with custom primary and secondary color.
// In this project we are defining the theme externally, and bringing it in through props
// import theme from '../styles/material-theme';

function withRoot(Component, theme) {
    const WithRoot = (props) => ( // ThemeProvider makes the theme available down the React tree thanks to React context.
        <ThemeProvider theme={theme}>
            {
                /*
                    CssBaseline kickstart an elegant,
                    consistent, and simple baseline to build upon.
                */
            }
            <CssBaseline />
            <Component {...props} />
        </ThemeProvider>
    );

    return WithRoot;
}

export default withRoot;
